import { ReactElement, useCallback } from 'react';
import cx from 'clsx';
import { DatePicker } from '@ff-it/ui';
import { parseDate, useField } from '@ff-it/form';
import { lastDayOfYear, lastDayOfMonth, startOfYear, startOfMonth } from 'date-fns';
import { InputButton } from './InputButton';

interface PeriodPickerProps {
  className?: string;
  fromName: string;
  toName: string;
  minDate?: Date;
  maxDate?: Date;
}

export function PeriodPickerField({ className, fromName, toName, minDate, maxDate }: PeriodPickerProps): ReactElement {
  const {
    input: { value: fromValue, onChange: onFromChange },
  } = useField(fromName, { subscription: { value: true } });

  const {
    input: { value: toValue, onChange: onToChange },
  } = useField(toName, { subscription: { value: true } });

  const onYearChange = useCallback(
    (date: Date | null) => {
      if (date) {
        onFromChange(parseDate(date));
        onToChange(parseDate(lastDayOfYear(date)));
      }
    },
    [onFromChange, onToChange],
  );

  const onMonthChange = useCallback(
    (date: Date | null) => {
      if (date) {
        onFromChange(parseDate(date));
        onToChange(parseDate(lastDayOfMonth(date)));
      }
    },
    [onFromChange, onToChange],
  );

  return (
    <div className={cx('form-group d-flex', className)}>
      <div>
        <DatePicker
          showYearPicker
          onChange={onYearChange}
          customInput={<InputButton>Year</InputButton>}
          selected={fromValue ? parseDate(fromValue) : null}
          minDate={minDate ? startOfYear(minDate) : undefined}
          maxDate={maxDate}
        />
      </div>
      <div className="ml-1">
        <DatePicker
          showMonthYearPicker
          onChange={onMonthChange}
          customInput={<InputButton>Month</InputButton>}
          selected={toValue ? parseDate(toValue) : null}
          minDate={minDate ? startOfMonth(minDate) : undefined}
          maxDate={maxDate}
        />
      </div>
    </div>
  );
}
