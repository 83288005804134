import { ReactElement, ReactNode } from 'react';
import { ControlledTableProps, KeyGetter, RowType, SizeProps } from './types';
import { ControlledTable } from './ControlledTable';
import { Box, TableColumns } from '@ff-it/ui';
import { TableFilter } from './TableFilter';
import { useSessionScope } from 'services';

export type IndexTableProps<R extends RowType> = {
  url: string;
  filterFields?: ReactNode;
  columns: TableColumns<R>;
  before?: ReactNode;
  after?: ReactNode;
  storageKey?: string;
  pageSize?: number;
} & Pick<ControlledTableProps<R>, 'defaultFilter' | 'queryParams'>;

export function IndexTable<R extends RowType>({
  url,
  filterFields,
  before,
  // use url as storageKey if we have filters
  // FIXME: probably not safe
  storageKey = filterFields !== null ? url : undefined,
  pageSize: providedPageSize,
  after,
  ...rest
}: IndexTableProps<R>): ReactElement {
  const { slug } = useSessionScope();
  const getKey: KeyGetter = ({ filter, pageSize, pageIndex, sort, queryParams }) => {
    return {
      url,
      method: 'GET',
      queryParams: {
        // FIXME
        ...filter,
        page_size: pageSize,
        page: pageIndex,
        ordering: sort ? `${sort.direction === 'DESC' ? '-' : ''}${sort.key.replace('.', '__')}` : undefined,
        ...queryParams,
      },
      _slug: slug,
    };
  };

  const sizeProps: SizeProps = providedPageSize
    ? {
        pageSize: providedPageSize,
      }
    : {
        defaultPageSize: 30,
      };

  return (
    <ControlledTable<R>
      // remount
      key={url}
      {...sizeProps}
      getKey={getKey}
      storageKey={storageKey}
      tableProps={{
        fontSize: 'sm',
        fixed: true,
        before: (
          <>
            {before}
            {filterFields !== null && (
              <Box display="flex" marginTop="sm">
                <TableFilter>{filterFields}</TableFilter>
              </Box>
            )}
          </>
        ),
        after,
      }}
      {...rest}
    />
  );
}
