import { memo, ReactElement } from 'react';
import { ActivityField, AgencyActivityTypesField, RemoveButton } from 'components';
import { RateFields } from 'components/company';
import { BonusForm, Rows, RowProps } from 'modules/guarantee/common/bonus';
import permissions from 'modules/guarantee/cvb/permissions';
import { useField, useIsDisabled } from '@ff-it/form';
import { CompanyField } from 'modules/core/company/components';
import { DepartmentField } from 'modules/supplier/department/DepartmentField';
import { Alert } from '@ff-it/ui';

const props = {
  size: 'sm' as const,
  placeholder: 'All',
};

export const Row = memo(function Row({ name, remove, index }: RowProps): ReactElement {
  const disabled = useIsDisabled();

  return (
    <tr>
      <td className="client">
        <CompanyField name={`${name}.client`} {...props} includeBranches={true} />
      </td>
      <td className="payer">
        <CompanyField name={`${name}.payer`} {...props} includeBranches={true} />
      </td>
      <td className="type">
        <AgencyActivityTypesField name={`${name}.type`} {...props} />
      </td>
      <td className="activity">
        <ActivityField name={`${name}.activity`} {...props} />
      </td>

      <td className="department">
        <DepartmentField name={`${name}.department`} {...props} />
      </td>

      <td className="provider border-left">
        <CompanyField name={`${name}.provider`} {...props} includeBranches={true} />
      </td>
      <RateFields name={name} />
      <td className="tools">
        <RemoveButton onClick={() => remove(index)} disabled={disabled} />
      </td>
    </tr>
  );
});

function AmbiguityWarning(): ReactElement | null {
  const {
    input: { value: rates },
  } = useField<any[]>('rates', { subscription: { value: true } });
  let justClient = false;
  let justPayer = false;

  // We get undefiened if last one is removed (final-from bug)
  (rates || []).forEach(({ client, payer }) => {
    if (client && !payer) {
      justClient = true;
    }
    if (!client && payer) {
      justPayer = true;
    }
  });

  if (justClient && justPayer) {
    return (
      <Alert variant="warning" heading="Lookup ambiguity">
        Avoid mixing client only and payer only rows in single document
      </Alert>
    );
  }

  return null;
}

export function Form(): ReactElement {
  return (
    <BonusForm sensitive={permissions.sensitive_info}>
      <AmbiguityWarning />
      <table className="table-rates">
        <thead>
          <tr>
            <th className="client">Client</th>
            <th className="payer">Payer</th>
            <th className="type">Type</th>
            <th className="activity">Activity</th>
            <th className="department">Department</th>
            <th className="client">Provider</th>
            <th className="rate">Current</th>
            <th className="rate">Target</th>
            <th className="rate">Final</th>
            <th className="tools" />
          </tr>
        </thead>
        <Rows Row={Row} />
      </table>
    </BonusForm>
  );
}
